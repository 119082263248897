import React from 'react';
import { Testimonial } from './components/Testimonial';

export const About = () => {
  return (
    <section className='container'>
      <div className='mb-3 py-4'>
        <h1 className="text-3xl font-bold text-gray-900 sm:text-4xl mb-3">Architecte d'intérieur et Décorateur </h1>
        <p className="text-xl text-gray-700">
          Depuis mon enfance, la mode, l'architecture, la décoration et le cinéma ont nourri ma créativité.
        </p>
      </div>
      <div className="grid grid-col xl:grid-cols-2 lg:grid-cols-2">
        <div>
          <img
            alt="Livret de décoration et projet personnalisée réalisé par Lucas Schmitt - Tous droits réservés"
            src="/assets/images/Mag-insta-2.webp"
            className="rounded-xl mb-3"
          />
        </div>
        <div className="ml-2 text-base">
          <p className='mb-3'>
            En grandissant, mon intérêt pour l'architecture s'est confirmé : je suis parti étudier l'architecture à l'Université Libre de Bruxelle où j'ai découvert ma vocation : l'architecture d'intérieur.
            De retour en Alsace, ma région d'origine, je me suis spécialisée dans la décoration d'intérieur.
            Après 3 années d'expériences en agence, fort de cette expérience, j'ai décidé de créer ma propre entreprise, « lulu », pour donner vie à ma vision et à ma passion pour le design intérieur.
          </p>
          <p className='mb-3'>
            À travers mon regard, je m’efforce de transformer vos intérieurs en des espaces uniques qui reflètent votre personnalité et vos aspirations. Je suis convaincue que chaque lieu peut devenir exceptionnel lorsqu'il est conçu avec soin et attention.
          </p>
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 py-3 mb-3">Je valorise la simplicité, le minimalisme, les couleurs et le bien-être.</h2>
          <p className='mb-3'>
            Un espace de vie ne doit pas seulement suivre les tendances, mais doit aussi incarner l’essence même de ceux qui y vivent. C’est pourquoi je m'engage à écouter attentivement vos besoins et à faire en sorte que votre lieu de vie soit le miroir de votre identité.
            Ensemble, nous créerons un environnement qui non seulement répondra à vos exigences esthétiques et fonctionnelles, mais qui sera également une véritable extension de vous-même.
          </p>
        </div>
      </div>
      <Testimonial />
    </section>
  )
}
