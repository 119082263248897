import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import feather from 'feather-icons';

export const Contact = () => {

  useEffect(() => {
    feather.replace();
  }, []);

  return (
    <section className='mb-3'>
      <main>
        <div className='mb-3 text-center py-6'>
          <p className='font-bold text-2xl'>Vous avez une question ou vous souhaitez un devis ?</p>

        </div>
        <div className='flex flex-wrap items-center mb-3 justify-evenly'>
          <article className='p-4'>
            <p className='font-medium text-xl mb-3'>Contactez-moi !</p>
            <p className='mb-3'>Du lundi au vendredi de 9h à 18h.</p>
            <div className='flex flex-col items-start mb-3'>
              <button className='btn btn-primary rounded-full mb-3 text-xl'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                </svg>
                <a href='tel:+33659817621'>06 59 81 76 21</a>
              </button>
              <button className='btn btn-primary rounded-full mb-3 text-xl'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                </svg>
                <a href='mail:+info.lucasschmitt@gmail.com'>info.lucasschmitt@gmail.com</a>
              </button>
              <button className='btn btn-primary rounded-full mb-3 text-xl'>
                <i data-feather='instagram' className="text-white w-6 h-6"></i>
                <Link to='https://www.instagram.com/lulu.artchi/'>Instagram</Link>
              </button>
            </div>
          </article>
          <article>
            <img
              src="assets/images/about.png"
              width={400}
              className='rounded-full'
            />
          </article>
        </div>
      </main>
    </section>
  )
}